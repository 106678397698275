// Emulating todo records which should be returned from API backend
// in the real world.
export default [
  {
    id:              1,
    uuid:            '',
    geo:             true,
    debug:           false,
    lastSync:        0,
    clientNumber:    '',
    clientName:      '',
    startWork:       0,
    startPosition:   '',
    endPosition:     '',
    pageStatus:      '',
    activeTimeEntry: 0,
  }
]

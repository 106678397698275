<template>
  <div class="ConfigList" :key="config.id">
    <p>Version: {{ version() }}</p>
    <p>UUID: {{ config.uuid }}</p>
    <p>Geo: {{ config.geo }}</p>
    <p>LastSync: {{ config.lastSync }}</p>
    <p>ClientNumber: {{ config.clientNumber }}</p>
    <p>ClientName: {{ config.clientName }}</p>
    <p>Start: {{ config.startWork }}</p>
    <p>Start Position: {{ config.startPosition }}</p>
    <p>End Position: {{ config.endPosition }}</p>
    <p>Status: {{ config.pageStatus }}</p>
    <p>Active TE: {{ config.activeTimeEntry }}</p>
    <p>Anzahl Einträge: {{ config.timeEntries.length }}</p>
    <p>Agent: {{ userAgent() }}</p>
    <p>iOS Standalone: {{ isStandaloneMode() }}</p>
    <p></p>
  </div>
</template>

<script>
import Config from '../models/Config'

export default {
  computed: {
    config () {
      return Config.find(1)
    }
  },
  
  methods: {
    version() {
      return document.title
    },
    userAgent() {
      let n = window.navigator
      if (n !== null) {
        return window.navigator.userAgent.toLowerCase()
      } else {
        return ''
      }
    },
    isStandaloneMode() {
      return ( ('standalone' in window.navigator) && (window.navigator.standalone) ? 'yes' : 'no' )
    },
  }
}
</script>

<style scoped>
</style>

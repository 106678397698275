<template>
  <ul class="time-entries">
    <li class="time-entry" :class="{ transfered: timeEntry.transfered && timeEntry.transferedFix }" :key="timeEntry.id" v-for="timeEntry in timeEntries">

      <div v-if="!canChange(timeEntry)" class="time-entry">
        <div class="line">
          <div>Beginn</div>
          <div>{{ startWork(timeEntry) }}</div>
        </div>
        <div class="line">
          <div>Ende</div>
          <div>{{ endWork(timeEntry) }}</div>
        </div>
      </div>
        
      <div v-if="canChange(timeEntry)" class="time-entry-open">
        <ul class="errors" hidden></ul>
        
        <div class="block">
          <div class="comment" v-if="changeVisible(timeEntry)">
            <label>Bemerkungen</label>
            <div>
              <textarea
                :value="timeEntry.jobComment"
                placeholder=""
                @input="e => { updateComment(timeEntry, e.target.value) }"
              ></textarea>
            </div>
          </div>
        </div>
        
        <div class="block">
          <div class="left">
            
            <div v-if="!changeVisible(timeEntry)">
              <div class="line">
                <div>Beginn</div>
                <div>{{ startWork(timeEntry) }}</div>
              </div>
              <div class="line">
                <div>Ende</div>
                <div>{{ endWork(timeEntry) }}</div>
              </div>
            </div>
            
            <div v-if="changeVisible(timeEntry)" class="time-entry-change">
              <div class="line">
                <div>Beginn</div>
                <div>
                  <datepicker
                    input-class="vue-datepicker-input"
                    :class="{fielderror: fieldError(timeEntry)}"
                    :format="customFormatter"
                    :value="startWorkAsDate(timeEntry)"
                    @selected="updateStartDate"
                  >
                  </datepicker>
        
                  <vue-timepicker
                    format="HH:mm"
                    :class="{fielderror: fieldError(timeEntry)}"
                    :minute-interval="5"
                    v-model="startTime"
                    hide-clear-button
                    @change="updateStartTime(timeEntry, $event)"
                  >
                  </vue-timepicker>

                </div>
              </div>
              <div class="line">
                <div>Ende</div>
                <div>
                  <datepicker
                    input-class="vue-datepicker-input"
                    :class="{fielderror: fieldError(timeEntry)}"
                    :format="customFormatter"
                    :value="endWorkAsDate(timeEntry)"
                    @selected="updateEndDate"
                  >
                  </datepicker>
        
                  <vue-timepicker
                    format="HH:mm"
                    :class="{fielderror: fieldError(timeEntry)}"
                    :minute-interval="5"
                    v-model="endTime"
                    hide-clear-button
                    @change="updateEndTime(timeEntry, $event)"
                  >
                  </vue-timepicker>
                </div>
              </div>            
            </div>
            
            <div v-if="!validateEntryChange(timeEntry)" class="error">
              Die Zeiteingabe enthält einen Fehler!
            </div>
          </div>
          <div class="right">
            <button v-on:click="showChange(timeEntry)">{{buttonLabel(timeEntry)}}</button>
          </div>
        </div>        
      </div>
      
      <div class="block">       
        <div class="reason" v-if="changeVisible(timeEntry)">
          <label>Grund Korrektur</label>
          <div>
            <textarea
              :class="{fielderror: fieldError(timeEntry)}"
              :value="timeEntry.changedReason"
              placeholder="Es muss eine Grund für die Korrektur eingetragen werden!"
              @input="e => { updateReason(timeEntry, e.target.value) }"
            ></textarea>
          </div>
          <div v-if=" timeEntry.changedReason === '' " class="error">Das Feld ist ein Pflichtfeld!</div>
        </div>
        <div class="client" v-if="!changeVisible(timeEntry)">{{ timeEntry.clientName }}</div>
      </div>

    </li>
  </ul>
</template>

<script>
import Config from '../models/Config'
import TimeEntry from '../models/TimeEntry'
import printDate from '../utils/printDate'
import sendAllToApi from '../utils/sendAllToApi'
import sendChangesToApi from '../utils/sendChangesToApi'
import deleteOldRecords from '../utils/deleteOldRecords'
import timeFromDate from '../utils/timeFromDate'
import dateWithoutTime from '../utils/dateWithoutTime'
import addZeroBefore from '../utils/addZeroBefore'
import validateChange from '../utils/validateChange'
import validateFieldError from '../utils/validateFieldError'
import apiDate from '../utils/apiDate'
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker/dist/VueTimepicker.common.js'
import moment from 'moment'
import axios from 'axios'

export default {
  printDate,
  sendAllToApi,
  sendChangesToApi,
  deleteOldRecords,
  Datepicker,
  timeFromDate,
  dateWithoutTime,
  addZeroBefore,
  validateChange,
  validateFieldError,
  apiDate,
  moment,
  
  components: {
    Datepicker,
    VueTimepicker
  },
  
  mounted () {
    sendAllToApi();
    sendChangesToApi();
    deleteOldRecords();
  },
  
  computed: {
    timeEntries () {
      return TimeEntry.query().orderBy('id', 'desc').get()
    },
    startTime: {
      get () {
        const id = Config.find(1).activeTimeEntry
        const timeEntry = TimeEntry.find(id)
        const date = new Date(timeEntry.startChanged > 0 ? timeEntry.startChanged : timeEntry.startWork)
        return {
          HH: addZeroBefore(date.getHours()),
          mm: addZeroBefore(date.getMinutes()),
          ss: addZeroBefore(date.getSeconds())
        }
      }
    },
    endTime: {
      get () {
        const id = Config.find(1).activeTimeEntry
        const timeEntry = TimeEntry.find(id)
        const date = new Date(timeEntry.endChanged > 0 ? timeEntry.endChanged : timeEntry.endWork)
        return {
          HH: addZeroBefore(date.getHours()),
          mm: addZeroBefore(date.getMinutes()),
          ss: addZeroBefore(date.getSeconds())
        }
      }
    },
  },

  methods: {
    startWork(timeEntry) {
      return printDate( new Date(timeEntry.startChanged > 0 ? timeEntry.startChanged : timeEntry.startWork) )
    },
    startWorkAsDate(timeEntry) {
      return ( new Date(timeEntry.startChanged > 0 ? timeEntry.startChanged : timeEntry.startWork) )
    },
    endWork(timeEntry) {
      return printDate( new Date(timeEntry.endChanged > 0 ? timeEntry.endChanged : timeEntry.endWork) )
    },
    endWorkAsDate(timeEntry) {
      return ( new Date(timeEntry.endChanged > 0 ? timeEntry.endChanged : timeEntry.endWork) )
    },
    canChange(timeEntry) {
      // 15 minute timeframe for timeEntry-change: 60*15*1000
      // && timeEntry.changedAt === 0
      return ( (new Date).getTime() < (timeEntry.endWork + 900000) ? true : false )
    },
    validateEntryChange(timeEntry) {
      return validateChange(timeEntry)
    },
    fieldError(timeEntry) {
      return validateFieldError(timeEntry)
    },
    showChange(timeEntry) {
      if ( Config.find(1).activeTimeEntry === 0 ) {
        // open form
        Config.dispatch('$update', { data: { id: 1, activeTimeEntry: timeEntry.id } });
      } else if ( timeEntry.changedAt > 0 || timeEntry.jobComment !== '' ) {
        if ( ( timeEntry.changedAt > 0 && timeEntry.changedReason === '' ) || validateChange(timeEntry) === false ) {
          // Reason missing or time-change not valid - skip changes
          TimeEntry.dispatch('$update', { data: { id: timeEntry.id, startChanged: 0, endChanged: 0, changedAt: 0 } });
        } else {
          // all fine: close form
          Config.dispatch('$update', { data: { id: 1, activeTimeEntry: 0 } });
          const now = (new Date).getTime();
          const uuid = timeEntry.uuid
          // TimeEntry.dispatch('$update', { data: { id: timeEntry.id, changedAt: now } });
          // try to update record to api
          if ( navigator.onLine && timeEntry.transfered === true ) {
            axios.put('https://zapp.putzzentrale.ch/api/v1/time_entries/'+uuid+'.json', {
              "time_entry": {
                'comments':         timeEntry.jobComment,
                'fixed_at':         apiDate(now),
                'fixed_reason':     timeEntry.changedReason,
                'fixed_started_at': apiDate(timeEntry.startChanged),
                'fixed_stopped_at': apiDate(timeEntry.endChanged)
              }
            })
            .then(function (response) {
              const t = TimeEntry.query().where('uuid', uuid).first()
              TimeEntry.dispatch('$update', { data: { id: t.id, transferedFix: true } });
            })
            .catch(function (error) {
              console.log('transfer data error: '+error);
              const t = TimeEntry.query().where('uuid', uuid).first()
              TimeEntry.dispatch('$update', { data: { id: t.id, transferedFix: false } });
            });
          } // if navigator.onLine
          
        } // else: all fine
      } else {
        // nothing changed: close form
        Config.dispatch('$update', { data: { id: 1, activeTimeEntry: 0 } });
      } // else: form close
    },
    changeVisible(timeEntry) {
      return timeEntry.id === Config.find(1).activeTimeEntry
    },
    buttonLabel(timeEntry) {
      //const element = document.getElementById('time-entry-'+timeEntry.id)
      //return ( element.hidden == true ? 'Korrektur' : 'Speichern' )
      return (timeEntry.id === Config.find(1).activeTimeEntry ? 'OK' : 'Bearbeiten')
    },
    customFormatter(date) {
      // https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
      return moment(date).format('DD.MM.YYYY');
    },
    updateReason(timeEntry, reason) {
      TimeEntry.dispatch('$update', { data: { id: timeEntry.id, changedReason: reason } });
    },
    updateComment(timeEntry, comment) {
      TimeEntry.dispatch('$update', { data: { id: timeEntry.id, jobComment: comment } });
    },
    updateStartDate(date) {
      // date is a date object
      const id        = Config.find(1).activeTimeEntry
      const timeEntry = TimeEntry.find(id)
      const oldDate   = (timeEntry.startChanged > 0 ? timeEntry.startChanged : timeEntry.startWork)
      const newDate   = dateWithoutTime(date.getTime())
      const oldTime   = timeFromDate(oldDate)
      // 'DD.MM.YYYY HH:mm:ss'
      const dateTime  = moment(newDate+' '+oldTime).toDate().getTime()
      const now       = ( Math.floor(dateTime / 60000) === Math.floor(timeEntry.startWork / 60000) ? 0 : (new Date).getTime() )
      TimeEntry.dispatch('$update', { data: { id: id, startChanged: dateTime, changedAt: now } });
    },
    updateStartTime(timeEntry,eventData) {
      const date      = new Date(timeEntry.startChanged > 0 ? timeEntry.startChanged : timeEntry.startWork)
      const oldDate   = dateWithoutTime(date.getTime())
      const newTime   = eventData['displayTime']
      const dateTime  = moment(oldDate+' '+newTime).toDate().getTime()
      const now       = ( Math.floor(dateTime / 60000) === Math.floor(timeEntry.startWork / 60000) ? 0 : (new Date).getTime() )
      TimeEntry.dispatch('$update', { data: { id: timeEntry.id, startChanged: dateTime, changedAt: now } });
    },
    updateEndDate(date) {
      // date is a date object
      const id        = Config.find(1).activeTimeEntry
      const timeEntry = TimeEntry.find(id)
      const oldDate   = (timeEntry.endChanged > 0 ? timeEntry.endChanged : timeEntry.endWork)
      const newDate   = dateWithoutTime(date.getTime())
      const oldTime   = timeFromDate(oldDate)
      // 'DD.MM.YYYY HH:mm:ss'
      const dateTime  = moment(newDate+' '+oldTime).toDate().getTime()
      const now       = ( Math.floor(dateTime / 60000) === Math.floor(timeEntry.endWork / 60000) ? 0 : (new Date).getTime() )
      TimeEntry.dispatch('$update', { data: { id: id, endChanged: dateTime, changedAt: now } });
    },
    updateEndTime(timeEntry,eventData) {
      const date      = new Date(timeEntry.endChanged > 0 ? timeEntry.endChanged : timeEntry.endWork)
      const oldDate   = dateWithoutTime(date.getTime())
      const newTime   = eventData['displayTime']
      const dateTime  = moment(oldDate+' '+newTime).toDate().getTime()
      const now       = ( Math.floor(dateTime / 60000) === Math.floor(timeEntry.endWork / 60000) ? 0 : (new Date).getTime() )
      TimeEntry.dispatch('$update', { data: { id: timeEntry.id, endChanged: dateTime, changedAt: now } });
    },
  } // methods
  
}
</script>

<style scoped>
li {
  padding: 10px;
  border-bottom: 1px solid #666;
  border-left: 5px solid red;
}

.time-entry > .line {
  display: block;
}

.time-entry > .line > div {
  display: inline-block;
}

.time-entry > .line > div:first-child {
  width: 60px;
}

.time-picker {
  margin-top: -2px !important;
}

.block {
  display: flex;
  flex-flow: row wrap;
  position: relative;
}

.block .left {
  width: auto;
  flex-grow: 2;
}

.block .right {
  width: auto;
  position: relative;
}

.block .line > div {
  display: inline-block !important;
}

.block .line > div:first-child {
  width: 60px;
}

.time-entry-change .line > div {
  display: block !important;
}
  
.time-entry-change .line + .line {
  margin-top: 15px;
}

.comment {
  flex-grow: 3;
}

.reason {
  flex-grow: 3;
  margin-top: 15px;
}

button {
  border: 1px solid var(--c-gray-light);
  border-radius: 2px;
  padding: 4px 8px;
  line-height: 30px;
  color: var(--c-gray);
  transition: all .3s;
  display: block;
  color: #222;
  background: #cfcfcf;
  right: 5px;
  top: 5px;
  position: absolute;
}

button:hover {
  border-color: var(--c-gray);
  font-weight: bold;
}

li.transfered {
  border-left: 5px solid green;
}

li:first-child {
  border-top: 1px solid #666;
}

textarea {
  width: 100%;
  height: 80px;
}

.error {
  padding: 0 10px;
  background: red;
  display: inline-block;
  color: white;
  font-weight: bold;
}

.time-entry-change + .error {
  margin-top: 7px;
}

.fielderror {
  border: 2px solid red;
}

</style>

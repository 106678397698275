<template>
  <section v-if="showElement(config,'showTimeEntries')" id="time-entries">
    <div class="container">
      <div class="header">
        <h2 class="title">Zeiteinträge</h2>
      </div>

      <TimeEntryList />
    </div>
  </section>
</template>

<script>
import Config from '../models/Config'
import TimeEntry from '../models/TimeEntry'
import TimeEntryList from './TimeEntryList'

export default {
  components: {
    TimeEntryList
  },

  computed: {
    config () {
      return Config.find(1)
    }
  },
  
  methods: {
    showElement (config,status='') {
      return config !== null ? (config.pageStatus === status) : false
    }
  }
  
}
</script>

<style scoped>
h2 {
  display: none;
}
</style>

<template>
  <div class="Description">
    <div v-if="uuidValid(config)===false">
      Installieren Sie ZAPP mit Ihrem persönlichen QR-Code
      <div v-if="config !== null">
        <input
          id="uuid-input"
          type="text"
          :value="config.uuid"
          placeholder="UUID"
          @input="e => { updateUUID(config, e.target.value) }"
        ></input>
      </div>
    </div>
    <div v-else-if="uuidValid(config) && isAppInstalled() === false">
      Installieren Sie ZAPP bitte auf dem Home-Bildschirm
    </div>
    <div v-else-if="uuidValid(config) && isAppInstalled()">
      <p id="clock" v-if="showElement(config) || showElement(config,'doWork')">{{current_time()}}</p>
      
      <button
        v-if="showElement(config) || showElement(config,'doWork')"
        :class="{green: config.startWork === 0, red: config.startWork !== 0}" 
        v-on:click="startStop(config)"
      >
        <span>QR-Code scannen</span>
        <span v-if="config.startWork === 0">Beginn der Arbeit</span>
        <span v-else-if="config.startWork !== 0">Ende der Arbeit</span>
      </button>
      
      <p id="working-time" v-if="config.startWork !== 0">
        <span id="start-work">{{startWork(config)}}</span>
        <span id="time-diff">{{timeDiff(config)}}</span>
      </p>

      <button
        v-if="showElement(config) && timeEntriesAvailable()"
        v-on:click="changePageStatus(config,'showTimeEntries')"
      >
         Erfasste Zeiten anzeigen
      </button>
      
      <button
        v-if="showElement(config,'showTimeEntries')" 
        class="button"
        v-on:click="changePageStatus(config)"
      >
        Zurück
      </button>
      
      <button class="button" v-on:click="sendToApi()" hidden>Daten übertragen</button>
      
      <p v-if="config.lastSync !== 0 && showElement(config)">
        <small>Letzte Übertragung: {{lastSync(config)}}</small>
      </p>
      
      <div id="scan">
        <button
          v-if="showElement(config,'startScan') || showElement(config,'startWork') || showElement(config,'endScan') || showElement(config,'endWork')"
          v-on:click="changePageStatus(config,( config.pageStatus === 'endScan' || config.pageStatus === 'endWork' ? 'doWork' : ''))"
        >
          Abbrechen
        </button>
        
        <div id="scan-area" hidden>
          <p id="loadingMessage">Kamera konnte nicht aktiviert werden</p>
          <canvas id="canvas"></canvas>
        </div>
        <p id="output">
          <span id="outputData"></span>
        </p>
        
        <button
          v-if="showElement(config,'startWork') || showElement(config,'endWork')"
          v-on:click="startStop(config)"
          :class="{green: showElement(config,'startWork'), red: showElement(config,'endWork')}"
        >
          <span v-if="config.startWork === 0">Beginn der Arbeit</span>
          <span v-if="config.startWork !== 0">Ende der Arbeit</span>
        </button>
        
        <p id="stop-without-scan" v-if="showElement(config,'endScan')" v-on:click="startStop(config)">Manuell beenden</p>
      </div>
            
      <p id="geo-error" v-if="config.geo === false">
        <span>Geo-Position ausgeschaltet ({{version()}})</span>
      </p>
      <p id="no-geo-error" v-else-if="config.geo !== false">
        <span>{{version()}}</span>
      </p>
      
    </div>
    
    <div v-if="showDebug(config)">
      <ConfigSection />
    </div>
    
  </div>
</template>

<script>
import Config from '../models/Config'
import TimeEntry from '../models/TimeEntry'
import jsQR from "jsqr";
import axios from 'axios';
import uuidgen from '../uuidgen'
import printDate from '../utils/printDate'
import apiDate from '../utils/apiDate'
import ConfigSection from './ConfigSection'

function calcTimeDiff () {
  const td = Math.ceil( (((new Date).getTime() - Config.find(1).startWork)/1000)/60 )
  return 'Arbeitszeit: ' + td + ' Minute' + (td > 1 ? 'n' : '')
}

function showStartWork () {
  return 'Start: '+printDate( new Date( Config.find(1).startWork ) )
}

function showCurrentTime () {
  const clock = document.getElementById('clock')
  if (clock !== null && clock.hidden === false) {
    clock.innerText = printDate( new Date(), ' | ' )
  }
  const time_diff = document.getElementById('time-diff')
  if (time_diff !== null && time_diff.hidden === false) {
    time_diff.innerText = calcTimeDiff()
  }
  const start_work = document.getElementById('start-work')
  if (start_work !== null && start_work.hidden === false) {
    start_work.innerText = showStartWork()
  }
  setTimeout(showCurrentTime,1000*15)
}

function getStartPosition (position) {
  console.log("getStartPosition")
  Config.dispatch('$update', { data: { id: 1, startPosition: position.coords.latitude+','+position.coords.longitude, geo: true } });
}

function getEndPosition (position) {
  console.log("getEndPosition")
  Config.dispatch('$update', { data: { id: 1, endPosition: position.coords.latitude+','+position.coords.longitude, geo: true  } });
}

function geoError (error) {
  Config.dispatch('$update', { data: { id: 1, geo: false } });
}

export default {
  uuidgen,
  printDate,
  apiDate,
  
  components: {
    ConfigSection
  },
  
  mounted () {
    showCurrentTime();
  },
  
  computed: {
    config () {
      return Config.find(1)
    }
  },
  
  methods: {
    version() {
      return document.title.replace('ZAPP ','')
    },
    showDebug(config) {
      return config !== null ? config.debug : false
    },
    current_time () {
      return printDate( new Date(), ' | ' )
    },
    timeDiff (config) {
      return calcTimeDiff()
    },
    startWork (config) {
      return showStartWork()
    },
    isApple() {
      if ( /iphone|ipad|ipod/.test( window.navigator.userAgent.toLowerCase() ) ) {
        return true
      } else {
        return false
      }
    },
    isAndroid() {
      if ( /android/i.test( window.navigator.userAgent.toLowerCase() ) ) {
        return true
      }
      else {
        return false
      }
    },
    isDesktop() {
      if ( /iphone|ipad|ipod/.test( window.navigator.userAgent.toLowerCase() ) ) {
        return false
      } 
      else if ( /android/i.test( window.navigator.userAgent.toLowerCase() ) ) {
        return false
      }
      else {
        return true
      }
    },
    isAppInstalled () {
      // Detects if device is in standalone mode
      if ( /iphone|ipad|ipod/.test( window.navigator.userAgent.toLowerCase() ) ) {
        // on iOS
        return true
        // return ('standalone' in window.navigator) && (window.navigator.standalone)
      } 
      else if ( /android/i.test( window.navigator.userAgent.toLowerCase() ) ) {
        // on Android
        return true
        // return (window.matchMedia('(display-mode: standalone)').matches)
      }
      else {
        // Desktop
        return true
      }
    },
    uuidValid(config) {
      if ( config !== null && config.uuid !== '' ) {
        var regex = /^[0-9A-F]{8}-[0-9A-F]{4}-[0-5][0-9A-F]{3}-[089AB][0-9A-F]{3}-[0-9A-F]{12}$/i
        var match = regex.exec( config.uuid.toUpperCase() )
        return (match !== null ? true : false)
      } else {
        return false
      }
    },
    updateUUID(config,eventData) {
      if ( config !== null && eventData !== '') {
        Config.dispatch('$update', { data: { id: 1, uuid: eventData.toUpperCase() } })
      }
    },
    showElement (config,status='') {
      return config.pageStatus === status
    },
    timeEntriesAvailable() {
      return TimeEntry.query().get().length > 0
    },
    startStop (config) {
      // like a state-machine: empty, startScan, startWork, doWork, endScan, empty
      const now = (new Date).getTime();
      // start scan
      if (config.pageStatus === '') {
        navigator.geolocation.getCurrentPosition(getStartPosition,geoError)
        config.pageStatus = 'startScan'
        Config.dispatch('$update', { data: { id: 1, pageStatus: config.pageStatus } });
        this.scanCode(config)
      }
      // start work
      else if (config.pageStatus === 'startWork') {
        const outputData = document.getElementById('outputData')
        if (outputData !== null) { outputData.innerText = '' }
        config.startWork = now
        config.pageStatus = 'doWork'
        Config.dispatch('$update', { data: { id: 1, startWork: now, pageStatus: config.pageStatus } });
      }
      // end scan 
      else if (config.pageStatus === 'doWork') {
        navigator.geolocation.getCurrentPosition(getEndPosition,geoError)
        config.pageStatus = 'endScan'
        Config.dispatch('$update', { data: { id: 1, pageStatus: config.pageStatus } });
        this.scanCode(config)
      }
      // end work
      else if (config.pageStatus === 'endWork' || config.pageStatus === 'endScan') {
        const outputData = document.getElementById('outputData')
        if (outputData !== null) { outputData.innerText = '' }
        const scanArea = document.getElementById('scan-area')
        if (scanArea !== null) { scanArea.hidden = true }
        const uuid = uuidgen()
        // create TimeEntry record
        TimeEntry.$create({ data: {
          uuid:          uuid,
          clientNumber:  config.clientNumber,
          clientName:    config.clientName,
          startWork:     config.startWork,
          startPosition: config.startPosition,
          endWork:       now,
          endPosition:   config.endPosition
        }})
        // try to send record to api
        if ( navigator.onLine ) {
          axios.post('https://zapp.putzzentrale.ch/api/v1/time_entries.json', {
            "time_entry": {
              'uuid':             uuid,
              'client_id':        config.clientNumber,
              'cleaner_uuid':     config.uuid,
              'started_at':       apiDate(config.startWork),
              'started_position': config.startPosition,
              'stopped_at':       apiDate(now),
              'stopped_position': config.endPosition
            }
          })
          .then(function (response) {
            const t = TimeEntry.query().where('uuid', uuid).first()
            TimeEntry.dispatch('$update', { data: { id: t.id, transfered: true } });
          })
          .catch(function (error) {
            console.log('transfer data error: '+error);
          });
        }
        // reset
        config.startWork = 0
        config.clientNumber = ''
        config.clientName = ''
        config.startPosition = ''
        config.endPosition = ''
        config.pageStatus = ''
        Config.dispatch('$update', { data: { id: 1, startWork: 0, clientNumber: '', clientName: '', startPosition: '', endPosition: '', pageStatus: '' } });
      }
    },
    changePageStatus (config, status='') {
      config.pageStatus = status
      Config.dispatch('$update', { data: { id: 1, pageStatus: status, activeTimeEntry: 0 } });
      const outputData = document.getElementById('outputData')
      if (outputData !== null) { outputData.innerText = '' }
      document.getElementById('scan-area').hidden = true;
    },
    lastSync (config) {
      printDate( new Date(config.last_sync) )
    },
    scanCode(config) {
      document.getElementById('scan-area').hidden = false;
      const video = document.createElement('video');
      const canvasElement = document.getElementById('canvas');
      const canvas = canvasElement.getContext('2d');
      const loadingMessage = document.getElementById('loadingMessage');
      const outputContainer = document.getElementById('output');
      const outputData = document.getElementById('outputData');

      function tick() {
        loadingMessage.innerText = 'Starte QR-Code-Scan...'
        if (video.readyState === video.HAVE_ENOUGH_DATA) {
          loadingMessage.hidden = true;
          canvasElement.hidden = false;
          outputContainer.hidden = false;
          
          canvasElement.height = video.videoHeight;
          canvasElement.width = video.videoWidth;
          canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
          var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
          var code = jsQR(imageData.data, imageData.width, imageData.height, {
            inversionAttempts: 'dontInvert',
          });
          if (code) {
            try {
              const data = JSON.parse(code.data);
              outputData.parentElement.hidden = false;
              canvasElement.hidden = true;
              outputData.innerText = data.name+'\n'+data.street+'\n'+data.zip+' '+data.city
              // TODO: video.stop(); ???
              // switch status
              if (config.pageStatus === 'startScan') {
                config.pageStatus = 'startWork' 
              } else if (config.pageStatus === 'endScan') {
                config.pageStatus = 'endWork'
              }
              Config.dispatch('$update', { data: { id: 1, clientNumber: data.client_id, clientName: data.name, pageStatus: config.pageStatus } });
              // exit: we are done!
              document.getElementById('scan-area').hidden = true;
              return;
            } catch (error) { 
              outputData.parentElement.hidden = false;
              canvasElement.hidden = true;
              outputData.innerText = "Falscher QR-Code, bitte erneut scannen"
            } // try / catch
          } else {
            outputData.parentElement.hidden = true;
          }
        } // if HAVE_ENOUGH_DATA
        requestAnimationFrame(tick);
      } // tick
      
      const nav = navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment" }
      }).then(function(stream) {
        video.srcObject = stream;
        video.setAttribute("playsinline", true);
        video.play();
        requestAnimationFrame(tick);
      });
    }, // video
    sendToApi () {
    },

  } // methods  
}
</script>

<style scoped>
@import "../styles/variables.css";

.Description {
  margin: 0 auto;
  text-align: center;
}

#uuid-input {
  margin-top: 15px;
  min-width: 350px;
  text-align: center;
}

button {
  margin: 1em auto;
  border: 1px solid var(--c-gray-light);
  border-radius: 2px;
  padding: 8px auto;
  line-height: 30px;
  color: var(--c-gray);
  transition: all .3s;
  display: block;
  width: 300px;
  color: #222;
  background: #cfcfcf;
}
button:hover {
  border-color: var(--c-gray);
  font-weight: bold;
}

button span {
  display: block;
}

a:nth-child(2) {
  margin-top: 1em;
  font-size: 120%;
  font-weight: bold;
}

a:nth-child(2):hover {
  color: white;
}

a:th-child(5) {
  margin-bottom: 0;
}

button.red, button.green {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 140%;
  color: white;
}

.green {
  background: green;
}

.red {
  background: red;
}

#canvas {
  width: 100%;
}

#start-work, #time-diff {
  display: block;
}

#stop-without-scan {
  margin-top: 1.5em;
}

#stop-without-scan:hover {
  text-decoration: underline;
}

#geo-error, #no-geo-error {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  padding: 0.5em 0;
  background: red;
  color: white;
  width: 100%;
}

#geo-error div {
  display: block;
  text-align: center;
}

#no-geo-error {
  background: white;
  color: inherit;
}

</style>

// include images with rails webpacker
require.context('../images/', true, /\.(gif|jpg|png|svg|eot|ttf|woff|woff2)$/i)

import Vue from 'vue'
import App from '../components/App.vue'

const app = new Vue(App)

if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/serviceworker.js', { scope: '/' })
    .then(function(reg) {
      console.log('[Companion]', 'Service worker registered!');
    });
}

app.$mount('#app')

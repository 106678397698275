<template>
  <div class="alert alert-dismissible alert-info" v-if="showInstallBanner">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    Wollen Sie <a href="#" @click.prevent="install">Zapp installieren?</a>
  </div>
</template>

<script>
let installEvent;

export default {
  name: 'installPrompt',
  
  data() {
    return {
      showInstallBanner: false
    };
  },
  
  created() {
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault();
      installEvent = e;
      this.showInstallBanner = true;
    });
  },
  
  methods: {
    install() {
      this.showInstallBanner = false;
      installEvent.prompt();
      installEvent.userChoice.then(() => {
        installEvent = null;
      });
    }
  }
  
};
</script>

<style scoped>
</style>

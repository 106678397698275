import addZeroBefore from './addZeroBefore'

const apiDate = function (date) {
  if ( date > 0 ) {
    const d = new Date(date)
    return d.getFullYear()+'-'+
           addZeroBefore(d.getMonth()+1)+'-'+
           addZeroBefore(d.getDate())+' '+
           addZeroBefore(d.getHours())+':'+
           addZeroBefore(d.getMinutes())+':'+
           addZeroBefore(d.getSeconds())
  } else {
    return ''
  }
}

export default apiDate
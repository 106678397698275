<template>
  <div class="App">
    <AppHeader />
    <AppButtons />

    <div class="container">
      <div class="time-entries"><TimeEntriesSection /></div>
    </div>

  </div>
</template>

<script>
import data from '../data'
import store from '../store'
import Config from '../models/Config'
import TimeEntry from '../models/TimeEntry'
import AppHeader from './AppHeader'
import AppButtons from './AppButtons'
import TimeEntriesSection from './TimeEntriesSection'
import sendAllToApi from '../utils/sendAllToApi'
import sendChangesToApi from '../utils/sendChangesToApi'

export default {
  store,
  sendAllToApi,
  sendChangesToApi,

  components: {
    AppHeader,
    AppButtons,
    TimeEntriesSection
  },

  data () {
    return {
      yay: 'Hello!'
    }
  },

  async mounted () {
    // should we restore config?
    let uuid = null
    const restore = new RegExp('[?&]restore=([^&#]*)').exec(window.location.href);
    // Fetch data from indexeddb
    await Config.$fetch()
    await TimeEntry.$fetch()
    // Setup config record
    if (Config.all().length === 0) {
      Config.$create({ data: data })
    }
    else if (restore !== null && decodeURI(restore[1]) === 'true') {
      // restore config to defaults
      uuid = Config.find(1).uuid
      Config.dispatch('$update', { data: data });
      // restore uuid if available
      if (uuid !== null && uuid !== '') {
        // redirect to setup uuid in config
        window.location = "/?t="+uuid;
      } else {
        // redirect to root-url without restore-param!
        // cause this is the page we want to install
        window.location = "/";
      }
    }
    // enable or disable the debug screen
    const debug = new RegExp('[?&]debug=([^&#]*)').exec(window.location.href);
    if (debug !== null && decodeURI(debug[1]) === 'true') {
      Config.dispatch('$update', { data: { id: 1, debug: true } });
      window.location = "/";
    }
    else if (debug !== null && decodeURI(debug[1]) === 'false') {
      Config.dispatch('$update', { data: { id: 1, debug: false } });
      window.location = "/";
    }
    // Get uuid from url
    const param = new RegExp('[?&]t=([^&#]*)').exec(window.location.href);
    if (param !== null) {
      uuid = decodeURI(param[1]) || null
    }
    // Store uuid in config record
    if (uuid !== null && uuid !== '') {
      //console.log('uuid: '+uuid)
      Config.dispatch('$update', { data: {
        id: 1,
        uuid: uuid
      } });
      // redirect to root-url without uuid-param!
      // cause this is the page we want to install
      // and cache !!!
      window.location = "/";
    }
    // Refetch config from indexdb
    await Config.$fetch()
        
    sendAllToApi();
    sendChangesToApi();
  } // mounted
}
</script>

<style src="../styles/bootstrap.css"></style>

<style scoped>
.App {
  padding-bottom: 4em;
}

.config {
  display: none;
  overflow: hidden;
  margin-bottom: 2em;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 2em auto 0 auto;
  width: 100%;
  background: #efefef;
}
</style>

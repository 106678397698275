<template>
  <div class="AppHeader">
    <h1 class="title">ZAPP</h1>
  </div>
  <install-prompt />
</template>

<script>
import InstallPrompt from './InstallPrompt'

export default {
  components: {
    InstallPrompt
  },
}
</script>

<style scoped>
@import "../styles/variables.css";

.AppHeader {
  background-image: url(/assets/logo.png);
  background-repeat:no-repeat;
  background-position: center center;
  height: 100px;
  margin-bottom: 1.5em;
}

.title {
  display: none;
}
</style>

import { Model } from '@vuex-orm/core'
import TimeEntry from './TimeEntry'

export default class Config extends Model {
  static entity = 'config'

  static fields () {
    return {
      id:               this.number(1),
      uuid:             this.string(''),
      geo:              this.boolean(true),
      debug:            this.boolean(false),
      lastSync:         this.number(0),
      clientNumber:     this.string(''),
      clientName:       this.string(''),
      startWork:        this.number(0),
      startPosition:    this.string(''),
      endPosition:      this.string(''),
      pageStatus:       this.string(''),
      activeTimeEntry:  this.number(0),
      timeEntries:      this.hasMany(TimeEntry, 'config_id')
    }
  }
}

import { Database } from '@vuex-orm/core'
import Config from '../models/Config'
import TimeEntry from '../models/TimeEntry'

const database = new Database()

database.register(Config)
database.register(TimeEntry)

export default database

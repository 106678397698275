import TimeEntry from '../models/TimeEntry'

const deleteOldRecords = function () {
  
  // delete records older than 30 days: 60*60*24*30*1000
  const now = (new Date).getTime();
  const ts = TimeEntry.query().where( 'endWork', value => value < (now-(2592000000)) ).get()
  ts.forEach(function(timeEntry, index) {
    TimeEntry.dispatch('$delete', timeEntry.id)
  });

}

export default deleteOldRecords
import TimeEntry from '../models/TimeEntry'
import Config from '../models/Config'
import apiDate from '../utils/apiDate'
import axios from 'axios';

const sendAllToApi = function () {
  
  if ( navigator.onLine ) {
    const ts = TimeEntry.query().where('transfered', false).orderBy('id', 'desc').get()
    const uuid = Config.find(1).uuid
    ts.forEach(function(timeEntry, index) {
      axios.post('https://zapp.putzzentrale.ch/api/v1/time_entries.json', {
        "time_entry": {
          'uuid':             timeEntry.uuid,
          'client_id':        timeEntry.clientNumber,
          'cleaner_uuid':     uuid,
          'comments':         timeEntry.jobComment,
          'started_at':       apiDate(timeEntry.startWork),
          'started_position': timeEntry.startPosition,
          'stopped_at':       apiDate(timeEntry.endWork),
          'stopped_position': timeEntry.endPosition,
          'fixed_at':         apiDate(timeEntry.changedAt),
          'fixed_reason':     timeEntry.changedReason,
          'fixed_started_at': apiDate(timeEntry.startChanged),
          'fixed_stopped_at': apiDate(timeEntry.endChanged)
        }
      })
      .then(function (response) {
        const changed = ( timeEntry.changedAt > 0 ? true : false )
        TimeEntry.dispatch('$update', { data: { id: timeEntry.id, transfered: true, transferedFix: changed } });
      })
      .catch(function (error) {
        console.log('transfer data error: '+error);
      });
    });
  } // if navigator.onLine
  
}

export default sendAllToApi
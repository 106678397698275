import TimeEntry from '../models/TimeEntry'
import Config from '../models/Config'
import apiDate from '../utils/apiDate'
import axios from 'axios';

const sendChangesToApi = function () {
  
  if ( navigator.onLine ) {
    const ts = TimeEntry.query().where('transferedFix', false).orderBy('id', 'desc').get()
    const uuid = Config.find(1).uuid
    ts.forEach(function(timeEntry, index) {
      if ( timeEntry.transfered === true ) {
        axios.put('https://zapp.putzzentrale.ch/api/v1/time_entries/'+timeEntry.uuid+'.json', {
          "time_entry": {
            'comments':         timeEntry.jobComment,
            'fixed_at':         apiDate(timeEntry.changedAt),
            'fixed_reason':     timeEntry.changedReason,
            'fixed_started_at': apiDate(timeEntry.startChanged),
            'fixed_stopped_at': apiDate(timeEntry.endChanged)
          }
        })
        .then(function (response) {
          TimeEntry.dispatch('$update', { data: { id: timeEntry.id, transferedFix: true } });
        })
        .catch(function (error) {
          console.log('transfer fix-data error: '+error);
          const t = TimeEntry.query().where('uuid', uuid).first()
          TimeEntry.dispatch('$update', { data: { id: t.id, transferedFix: false } });
        });
      } // if transfered
    }); // forEach
  } // if navigator.onLine
  
}

export default sendChangesToApi
<template>
  <section class="Users">
    <div class="container">
      <div class="header">
        <h2 class="title">Configuration</h2>
      </div>

      <ConfigView />
    </div>
  </section>
</template>

<script>
import Config from '../models/Config'
import ConfigView from './ConfigView'

export default {
  components: {
    ConfigView
  }
}
</script>

<style scoped>
@import "../styles/variables.css";

.button {
  border: 1px solid var(--c-gray-light);
  border-radius: 2px;
  padding: 0 16px;
  line-height: 30px;
  font-size: 12px;
  color: var(--c-gray);
  transition: all .3s;
}

.button:hover {
  border-color: var(--c-gray);
  color: var(--c-black);
}

h2 {
  text-decoration: underline;
}
</style>

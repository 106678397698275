import { Model } from '@vuex-orm/core'
import Config from './Config'

export default class TimeEntry extends Model {
  static entity = 'timeEntries'

  static fields () {
    return {
      id:             this.increment(),
      config_id:      this.number(1),
      uuid:           this.string(''),
      clientNumber:   this.string(''),
      clientName:     this.string(''),
      startWork:      this.number(0),
      startPosition:  this.string(''),
      endWork:        this.number(0),
      endPosition:    this.string(''),
      changedAt:      this.number(0),
      jobComment:     this.string(''),
      changedReason:  this.string(''),
      startChanged:   this.number(0),
      endChanged:     this.number(0),
      transfered:     this.boolean(false),
      transferedFix:  this.boolean(true),
      assignee:       this.belongsTo(Config, 'config_id')
    }
  }
}
